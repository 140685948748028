
.custom-img {
    position: absolute;
    bottom: -24px;
    right: 0;
}

.tablet-hide {
    display: none;
}

.mobile-hide {
    display: none;
}
